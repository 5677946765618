<template>
<div class="row px-0">
    <div class="col-12 pt-2 px-0">
        <table id="basic-datatable" class="table table-striped  dt-responsive nowrap db"></table>
    </div>
</div>
</template>
<script>
/* eslint-disable */
import firebase from "firebase/app"
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import $ from 'jquery'

import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
const functions = firebase.app().functions('asia-east2')
import 'busy-load';

// import "datatables.net"
require('datatables.net-buttons-bs4')
require('datatables.net-responsive-bs4')
require('datatables.net-select-bs4')
require('datatables.net-bs4')
require('lightbox2/dist/js/lightbox.js')
require('lightbox2/dist/css/lightbox.css')
import Swal from 'sweetalert2'
export default {
    mounted() {
        let vue = this
        let columns = [
            {
                data: 'appLogo',
                title: "Logo",
                render: function (data, type, row) {
                    return `<a href="${data}" data-lightbox="image-1"><img src="${data}" width=50 ></a>`
                }
            },
            {
                data: 'appName',
                title: "Name",
                render: function (data, type, row) {
                    return `<b>${data}</b><br>${row['appDes']}`
                }
            },
            {
                data: 'id',
                title: "ID",
                render: function (data) {
                    return `<a href="https://www.dj.in.th/s/${data}" target="_blank">${data}</a>` 
                }
            },
            {
                data: 'appBuildTime',
                title: "Build Time",
                render: function (data) {
                    return `<span style="display:none;">${data}</span>` + dayjs(data).locale('th').format('DD MMMM YYYY')
                }
            },
            {
                data: 'appExpire',
                title: "License Expire",
                render: function (data) {
                    return `<span style="display:none;">${data}</span>` + dayjs(data).locale('th').format('DD MMMM YYYY')
                }
            },
            {
                data: 'appStatus',
                title: "Status",
                render: function (data, type, row) {
                    if(data === "PAID")
                        return `<span class='text-success'>${data}</span>`
                    else if(data === "EXPIRE")
                        return `<span class='text-danger'>${data}</span>`
                    else
                        return `<span class='text-warning'>${data}</span>`
                }
            },
            {
                data: 'appDL',
                title: "Download",
                render: function (data) {
                    return `<a href="${data}" target="_blank">${data}</a>` 
                }
            },
        ]
        let table = $('#basic-datatable').DataTable({
            order: [[3, "desc"]],
            pageLength: 50,
            lengthChange: !1,
            responsive: true,
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>"
                }
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
            columns: columns,
            select: 'single',
            columnDefs: [{
                "defaultContent": "",
                "targets": "_all",
                "className": "align-middle",
            }],
            buttons: [{
                    extend: 'selectNone',
                    text: 'Topup',
                    action: function (e, dt, node, config) {
                        Swal.fire({
                            title: 'How many?',
                            text: "Please input amount do you want to topup",
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Confirm',
                            input: 'text',

                        }).then(async(result) => {
                            if (result.isConfirmed)
                            {
                                let topupAmount = Number(result.value)
                                if(topupAmount<=0)
                                    vue.g_errorMsg(`Amount should be > 0`)
                                else
                                {
                                    vue.loadshow(true)
                                    console.log("topupAmount",topupAmount)
                                    var l = table.rows(".selected").data()[0]
                                    let getOwner = await firebase.database().ref(`api/stations/${l.id}`).once('value')
                                    let ownerId = getOwner.val().owner
                                    // admin uid for test : o2ZQT28E4sZTAwRGnE8WiK22cka2
                                    let json = {uid: ownerId,amount : topupAmount}
                                    await functions.httpsCallable('djAdminTopup')(json).then((result)=>{
                                        Swal.fire('Successful', `Owner Uid : ${ownerId} has been topup ${topupAmount}`, 'success')
                                    }).catch(error => {vue.g_errorMsg(error.message)})
                                    vue.loadshow(false)
                                }
                            }
                        })
                    }
                },
                {
                    extend: 'selectNone',
                    text: 'Make Screenshot',
                    action: function (e, dt, node, config) {
                        Swal.fire({
                            title: 'How many?',
                            text: "Please input amount do you want to topup",
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Confirm',
                            input: 'text',

                        }).then(async(result) => {
                            if (result.isConfirmed)
                            {
                                let topupAmount = Number(result.value)
                                if(topupAmount<=0)
                                    vue.g_errorMsg(`Amount should be > 0`)
                                else
                                {
                                    vue.loadshow(true)
                                    console.log("topupAmount",topupAmount)
                                    var l = table.rows(".selected").data()[0]
                                    let getOwner = await firebase.database().ref(`api/stations/${l.id}`).once('value')
                                    let ownerId = getOwner.val().owner
                                    // admin uid for test : o2ZQT28E4sZTAwRGnE8WiK22cka2
                                    let json = {uid: ownerId,amount : topupAmount}
                                    await functions.httpsCallable('djAdminTopup')(json).then((result)=>{
                                        Swal.fire('Successful', `Owner Uid : ${ownerId} has been topup ${topupAmount}`, 'success')
                                    }).catch(error => {vue.g_errorMsg(error.message)})
                                    vue.loadshow(false)
                                }
                            }
                        })
                    }
                },
            ]
        })
        table.buttons().container().appendTo("#basic-datatable_wrapper .col-md-6:eq(0)")
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {

                let insert = []
                firebase.database().ref('api/application/djcore').once('value').then(function (data) {
                    let fetchData = data.val()
                    Object.keys(data.val()).forEach((key) => {
                        let l = fetchData[key]
                        l.id = key
                        insert.push(l)
                    })
                    table.rows.add(insert).draw(true)
                })
            }
            else
                window.location.href = "/login"
        })

    },
}
</script>

<style>
.dataTables_wrapper .dataTables_filter {
    display: none;
}

.dataTables_paginate {
    float: right;
    text-align: right;
}

table.dataTable tbody>tr.selected {
    color: white
}
table.dataTable tbody>tr.selected a {
    color: white
}

.dt-buttons {
    margin-bottom: 10px !important;
}
</style>
